import { Header, HeaderProps, useCaseType } from '@softopus-io/rimac-ui-lib';
import { useRouter } from 'next/router';

import { NavigationQuery } from '@/api';

interface NavigationProps {
  invertedColor?: boolean;
  data: NavigationQuery;
}

export const Navigation = ({ invertedColor, data }: NavigationProps) => {
  const navData = data?.navigation?.data?.attributes;
  const { pathname } = useRouter();
  if (!navData) return null;

  const headerProps: HeaderProps = {
    useCase: 'technology' as useCaseType,
    menuLinksOpenedColOne: (
      navData.navigation.openMenuLinksFirstColumn || []
    ).map((link) => ({
      label: link?.label || '',
      path: link?.path || '',
      openInNewTab: !!link?.openInNewTab,
    })),
    menuLinksOpenedColTwo: (
      navData?.navigation?.openMenuLinksSecondColumn || []
    ).map((link) => ({
      label: link?.label || '',
      path: link?.path || '',
      openInNewTab: !!link?.openInNewTab,
    })),
    menuLinksClosed: (navData.navigation.closedMenuLinks || []).map((link) => ({
      label: link?.label || '',
      path: link?.path || '',
      openInNewTab: !!link?.openInNewTab,
    })),
    contact: {
      contactLink: {
        label: navData.contact.label,
        path: navData.contact.path,
      },
      instagram: navData.contact.instagram || undefined,
      linkedin: navData.contact.linkedin || undefined,
      tiktok: navData.contact.tiktok || undefined,
      youtube: navData.contact.youtube || undefined,
    },
    activePath: pathname,
    companyContactInfo: {
      name: navData.companyContactInfo?.name || '',
      address: navData.companyContactInfo?.address || '',
    },
  };

  return <Header {...headerProps} invertedColor={invertedColor} />;
};
